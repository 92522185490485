import React, {useState} from 'react';
import {captionClasses, StyledCaption} from "./StyledCaption";
import {Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {primary} from "../../common/theme";
import { useNavigate } from "react-router-dom";
import {NavigationRoutes} from "../../routes";
import { countries } from "../../Stories/stories-items";
import homeHero from "../../assets/homeHero.jpg";

export const Caption = () => {
    const navigate = useNavigate();
    const [country, setCountry] = useState(countries[0]);

    const gridContainerStyle = {
        display: 'none',
        position: 'relative',
        paddingTop: '30px',
        marginLeft: '-20px',
        marginBottom: '25px',
        paddingLeft: '20px',
        backgroundSize: '80%',
    };

    const mainContainerStyle = {
        backgroundImage: 'url('+homeHero+')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    const barContainerStyle = {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    }

    const handleChange = (event: any) => {
        setCountry(event.target.value);
    };

    return <StyledCaption className={captionClasses.root}>
        <Grid item sx={mainContainerStyle} className="heroClass">
            <Container maxWidth={"lg"}>
                <Grid container sx={{paddingTop: "150px", paddingBottom: "55px", position: "relative", minHeight: "calc(100vh - 210px)" }}>
                    <Grid item md={8} lg={6.5}>
                        <Grid container sx={{position: "relative", backgroundColor:"rgba(255,255,255,0.75)", padding:"40px 20px 20px", marginBottom: "40px" }}>
                            <Typography variant={"caption"} component={"div"} sx={{color: "#011E3F", textAlign: "left", zIndex: 100,  position: "relative"}}>
                                About the Learning by Sharing Portal (LSP)
                            </Typography>
                            <Typography sx={{color: "#011E3F", margin: "30px 0 20px", textAlign: "left", fontSize: "20px"}}>
                                The SRHR-UHC Learning by Sharing Portal aims to bring together implementers and advocates through a growing community of practice.
                            </Typography>
                        </Grid>
                        <Grid container sx={{
                            maxWidth: "480px",
                            background: "white",
                            padding: "10px",
                            height: "80px",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <FormControl  id="demo-simple-fc">
                                <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={country}
                                    label="Country"
                                    variant={"standard"}
                                    sx={{ width: "150px"}}
                                    onChange={handleChange}
                                >
                                    {countries.map((innerCountry)=>{
                                        return <MenuItem key={innerCountry} value={innerCountry}>{innerCountry}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <Button variant={"contained"} sx={{background: primary, borderRadius: "10px"}}
                                    onClick={()=>navigate(`/${NavigationRoutes.Stories}/${country}`)}>
                                Explore now
                            </Button>
                        </Grid>

                        <Grid container sx={gridContainerStyle}>
                            <Grid container sx={{position: "relative", paddingTop: "30px"}}>
                                <Typography component={"div"} sx={{color: "white", textAlign: "left", zIndex: 100,  position: "relative", fontSize: "28px", lineheight: "32px", fontWeight: "500"}}>
                                    Call for Implementation Stories
                                </Typography>
                            </Grid>
                            <Typography variant={"h3"} sx={{color: "white", margin: "10px 0 30px 0", textAlign: "left", fontSize: "18px", lineheight: "28px"}}>
                                Deadline 15th August 2023
                            </Typography>
                            <Typography variant={"h3"} sx={{color: "white", margin: "10px 0 30px 0", textAlign: "left", fontSize: "18px", lineheight: "28px", maxWidth: "90%"}}>
                                Submit a story and contribute to the universal health coverage agenda and guide national-level decision-makers
                            </Typography>
                            <Button variant={"contained"} sx={{color: primary, backgroundColor: "#E38A15", borderRadius: "10px", padding: "10px 20px", '&:hover': { color: '#FFFFFF' },}}
                                        href={"https://www.who.int/news-room/articles-detail/implementation-stories-sharing-portal-sexual-and-reproductive-health-and-rights-and-uhc"} target="_blank">
                                Submit a Story
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* FIXME: Dont do this*/}
                <Grid sx={{"height": "50px", width: "100%"}} />
            </Container>
        </Grid>
        <Grid item sx={barContainerStyle}>
            <Container maxWidth={"xl"}>
                <Grid container sx={{padding: "15px 0", position: "relative" }}>
                    <Grid item lg={12}>
                        <Grid container sx={{position: "relative", padding:"0", justifyContent: "center" }}>
                            <Typography variant={"h3"} sx={{color: "#FFF", fontWeight:"bold", margin: "0", textAlign: "center", fontSize: "20px", maxWidth: "990px"}}>
                            The 2024 ERG call is now closed. Thank you for your submissions! Check out our latest implementation stories <a href="/stories" style={{color: "#FFF"}}>here</a>. If you have a story to share, please complete <a href="https://form.jotform.com/241827346668064" target="_blank" style={{color: "#FFF"}}>this form</a>.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </StyledCaption>;
};
