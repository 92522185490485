import { IStory } from "./interfaces/IStory";
import pakistan3 from './stories-images/pakistan3.jpg';
import india from './stories-images/india.jpg';
import kazakhstan from './stories-images/kazakhstan.jpg';
import malawi from './stories-images/malawi.jpg';
import mexico from './stories-images/mexico.jpg';
import nepalChw from './stories-images/nepal_chw.jpg';
import nepalSac from './stories-images/nepal_sac.jpg';
import somalia from './stories-images/somalia.jpg';
import pakistan from './stories-images/pakistan.jpg';
import pakistan2 from './stories-images/pakistan2.jpg';
import zambia from './stories-images/zambia.jpg';

export const stories: IStory[] = [

    {
        id: "11",
        country: "Pakistan",
        image: pakistan3,
        imagealt: "Rural ambulance service: a public-private transport solution for pregnant women in Punjab, Pakistan",
        pdf: "/pdfs/WHO LSP Story Pakistan 2024 D3.pdf",
        header: "Rural ambulance service: a public-private transport solution for pregnant women in Punjab, Pakistan",
        imageCredit: `All rights reserved.`,
        sixtySecondsSummary: "Delays in accessing a health facility at the time of delivery can have catastrophic consequences. In May 2017, a rural ambulance service for obstetric and neonatal care was introduced in Punjab province, Pakistan. Accessible by calling a toll-free number, the ambulance service is designed to collect all normal and high-risk pregnant women from their homes at the time of delivery, and throughout pregnancy for antenatal complications. Since its launch, the ambulance service has transferred over three million women from their homes to health facilities, around three and a half million women from primary to secondary or tertiary hospitals, and around 10 000 children for urgent referrals. An average of 2800 women are transferred each day across the province.",
        sexualAndReproductiveHealthChallenge: `Maternal mortality rates were estimated to be 178/100 000 live births in Pakistan in 2015. Approximately 30% of maternal deaths in Pakistan are attributable to the second delay in the ‘Three Delays Model’, that is the delay in accessing a health facility due to lack of transport. Many women who cannot reach primary care facilities have to deliver at home. In complicated cases, lack of access to emergency obstetric and neonatal care can have catastrophic consequences.
<br><br>In Punjab, the most populous province of Pakistan, the government scaled up around 700 basic health units in 2015, equipping them with 24-hour basic obstetric care services. By 2017, the number of health units had increased to 1000. However, access to these units for women in rural communities remained challenging. 
<br><br>The Government of Pakistan provided an ambulance service through the health system but due to misuse of vehicles, lack of timely maintenance and lethargy in public sector service delivery, it was ineffective. The government developed and endorsed the national universal health coverage (UHC) benefits package and essential package of health services (EPHS). Punjab province has adapted this benefits package to its context, including the provision of a rural ambulance service for obstetric and neonatal care.
`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `The ambulance service is designed to collect all normal and high-risk pregnant women from their homes at the time of delivery, and throughout pregnancy for identified antenatal complications. The service can be reached by calling a toll-free number (1034). The ambulance takes the woman to a primary care facility and waits for an initial screening. If the primary care staff feels the need to refer the woman to a higher-level hospital, the same ambulance takes the woman there. If the staff are comfortable conducting a normal vaginal delivery (NVD) at primary care level, the ambulance goes back to its resting point and awaits the next client.
<br><br>In a new step, the government out-sourced operation of the ambulance service through a unique tripartite arrangement: the central call centre is run and managed by a telecoms operator, the day-to-day vehicle operations are run by a private car rental company, and the technical and financial aspects are managed by the government’s Integrated Reproductive, Maternal and Child Health & Nutrition (IRMNCHN) programme.
<br><br>The call centre is manned by a team of call agents who handle an average of 5000 incoming calls per day. The entire operation is managed through a real-time dashboard that reflects the ambulance locations (tracked through GPS trackers), health facility locations, and other relevant details using Google maps. The call agent’s screen shows the ambulances available in a particular area, and the agent can assign the one nearest to the woman’s home. Once the ambulance is assigned, the agent identifies the nearest health facility from the same map. A text message is then sent to the driver as well as the client as a confirmation. The text message sent to the client contains the name of the assigned driver, his contact number, and the vehicle’s registration number. The text message sent to the driver contains the name of the client and her contact information.
<br><br>The vehicle operations are overseen by provincial and district level managers hired by the private rental company. The company is also responsible for provision of fuel, drivers, and repair/maintenance of the ambulance. The agreement with the rental company enables the government to track the performance of each vehicle continuously through the dashboard. The key condition of the contract was that “the ambulance engine would turn on within two minutes of a case being assigned to the vehicle, and no excuse for driver or fuel unavailability would be acceptable.”
`,
        success: {
            type: "text",
            text: `Since its launch in May 2017, the ambulance service has transferred over three million women from their homes to health facilities, around three and a half million women from primary to secondary or tertiary hospitals, and around 10000 children, aged under five years, for urgent referrals. An average of 2800 women are transferred each day across the province, including public holidays. It has been estimated that at least half of the 500 000 emergency referrals to secondary and tertiary care hospitals have prevented severe morbidity and maternal mortality. The cost per transfer for an average case is approximately US$ 10–15. In the past, government-run ambulance systems were cost-intensive and heavily misused by the local authorities. Centralizing the system has helped prevent misuse of the service.
The success of the rural ambulance service in Punjab, implemented through an outsourced model, is evident from the reduction in maternal mortality in Punjab to 157/100 000 live births in 2019, increase in skilled birth attendance, and improvement in timeliness of access to maternal care services in rural areas. All three million women who have used the service would have had to arrange transport on their own if the ambulance was not available.`,
        },
        challenges: {
            type: "text",
            text: `Locating addresses in rural areas is a challenge, as streets and house numbers are often unmapped, and so most of the clients and their caretakers are unable to provide exact addresses. This is being addressed through exchange of mobile phone numbers of ambulance drivers and clients/caretakers through automated text messages sent out to both parties once a case is assigned on the dashboard, so they can call and ask for the exact location. `,
        },
        lessons: {
            type: "bullets",
            bullets: [
                `The provision of a transport solution empowers women to seek quality obstetric services at the time of delivery and is more sustainable than providing cash or transport vouchers.`,
                `Outsourcing the non-technical aspects of public sector service delivery can be cost-effective to provide a good quality service. To achieve this, an effective contracts management unit that can develop and monitor a comprehensive contract for service provision is needed.`,
                `Learning from this experience, the Government has further expanded the fleet of ambulances to a total of 600 ambulances, and increased the scope of public-private partnerships to other services such as running a locum-based model for provision of skilled human resources, coverage of outreach health services, etc.`
            ],
        },
        authors: [
            `Ali Jan Khan, Secretary, Primary & Secondary Healthcare Department, Government of the Punjab, Pakistan. secy.pshealth@punjab.gov.pk`,
            `Naeem Majeed, Chief Executive, SPHERE Consulting Pakistan; former Additional Director, IRMNCHN Program, Primary and Secondary Healthcare Department, Punjab, Pakistan. nmajeed@gmail.com`,
        ],
        getInTouch: ``,
        sources: [
            `National Institute of Population Studies (NIPS) [Pakistan] and ICF. 2019 Pakistan Maternal Mortality Survey Summary Report. Islamabad, Pakistan, and Rockville, Maryland, USA: NIPS and ICF; 2020 (https://dhspro- gram.com/pubs/pdf/SR267/SR267.pdf, accessed 1 May 2024).`,
            `Integrated reproductive, maternal, neonatal and child health. Lahore, Pakistan: Primary & Secondary Health-care Department (https://pshealthpunjab.gov.pk/Home/VerticalProgramIRMNCH, accessed 1 May 2024).`,
            `Majeed N, Rashid A. Provision of round the clock basic obstetric and neonatal care services in rural settings: A low cost, high impact intervention in Punjab, Pakistan. Annals of King Edward Medical University. 2019; 25: 236–244.`,
            `Chaudhry S. Govt to introduce rural ambulance service for expectant mothers. Daily Times. 24 November 2016 (https://dailytimes.com.pk/44284/govt-to-introduce-rural-ambulance-service-for-expectant-mothers/; accessed 1 May 2024).`,
            `Ambulance service for expecting mothers launched in Lahore. The Express Tribune. 18 December 2016 (https://tribune.com.pk/story/1267220/safe-delivery-ambulance-service-expecting-mothers-launched-city; accessed 1 May 2024).`,
        ]
    },
    {
        id: "1",
        country: "India",
        image: india,
        imagealt: "This picture reflects the context of the story, community health workers help reach adolescent girls in India",
        pdf: "/pdfs/India - Community health workers reach adolescent girls in Maharashtra State D4.pdf",
        header: "Community health workers reach adolescent girls in Maharashtra State",
        imageCredit: `Paula Bronstein/Getty Images/Images of Empowerment`,
        sixtySecondsSummary: "Accredited social health activists (ASHAs), in Maharashtra state are female, school-educated, village-level volunteers who help increase awareness on health and mobilize the community to utilize existing health services. They conduct monthly needs assessment and morbidity surveillance among married adolescent girls and provide needs-specific behaviour change communication through interpersonal or group interactions and referrals to service delivery platforms within the village or government health centres. Central to engaging with these girls is the concept of self-reporting concerns during pregnancy to either the ASHA or the health facility, resulting in better maternal outcomes.",
        sexualAndReproductiveHealthChallenge: `The Indian government launched the National Adolescent Health Programme in 2014, which provides comprehensive services to adolescents, including treatment for maternal and menstrual health problems and sexually transmitted infections. Despite various interventions, the age-specific fertility rate for adolescents continues to show an upward trend, from 10.7 per 1000 in 2016 to 13 in 2017. Notwithstanding high antenatal registration and institutional delivery (> 75%), 14% of pregnancies have poor outcomes among adolescent girls.`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `The Institute of Health Management Pachod (IHMP) – a nongovernmental organization (NGO) – aligned programming to existing government platforms and used a bottom-up approach to address adolescent girls’ maternal health needs. In 2003, IHMP conducted an intervention research study to avert the adverse consequences of early motherhood in adolescents in 50 villages in Aurangabad and 27 slums in Pune city. This pilot project developed a package of needs-specific health services for married adolescent girls in rural settings of the Aurangabad District, Maharashtra State, which was scaled up in 2008 to five districts. The project integrated with services at seven government primary health centers chosen based on their low performance on reproductive and child health indicators. <br/><br/>
This approach led to the engagement of accredited social health activists (ASHAs), who are female, school-educated, village-level volunteers trained by the Ministry of Health and Family Welfare to create awareness and mobilize the community to utilize existing health services. These front-line workers conducted monthly needs assessments and morbidity surveillance among married adolescent girls aged 10–19 years at specified project sites. Needs-specific behaviour change communication (BCC) is provided through interpersonal or group interactions, and referrals to service delivery platforms within the village or at the government health centers. Central to this engagement is the concept of self-reporting concerns during pregnancy to either the ASHA or the health facility. IHMP developed a booklet on a monthly care requirement during pregnancy targeting adolescents, focusing on BCC and how pregnant women could self-monitor or self-report during the period. <br/><br/>
Community-based Village Health, Sanitation and Nutrition Committees (VHSNCs) in rural areas and Women Health Committees (MAS) in urban areas manage the ASHA-based model. VHSNCs monitor the intervention, at the community level by assessing the completeness and accuracy of the monthly needs assessment and morbidity surveillance data, comparing it with the service records at the government health centers. VHSNCs follow up on cases that do not receive services as per the standard service protocols and identify gaps in implementation at various levels of service delivery. <br/><br/>
The Government of Maharashtra revised their outcome-based incentive payment to ASHAs to include outputs to motivate compliance with processes adopted and ensure sustainability and institutionalization. For example, the Government pays incentives for developing a micro-plan based on monthly surveillance. As per the Government’s guidelines, ASHAs receive a fixed monthly incentive of approximately US$ 27, in addition to task-based incentives approved at the State level. The State Policy plan includes some of the strategies adopted while the Project Implementation Plan budget provides for additional output based incentives to ASHAs’ income. Finally, through the development of comprehensive monitoring systems, routine data collection systems have been put in place. A pre–post analysis of project sites showed increases in early antenatal registration, utilization of recommended antenatal care, and availing of health services.`,
        success: {
            type: "text",
            text: `The accredited social health activist (ASHA), -based model of care reaches adolescent girls and allows them to practise self-monitoring and self-care in pregnancy. In seven years, between 2011 and 2018, a pre-post analysis of project sites shows increases in early antenatal registration, utilization of recommended antenatal care, and decreases in self-reported symptoms, alongside increases in availing of health services for ante- and postnatal complications among adolescent girls.
The State revised its management information system to reflect IHMP’s monthly surveillance of ASHAs. The process succeeded in building and nurturing strong partnerships between the Institute of Health Management Pachod (IHMP), local communities and district- and state-level governments, paving the way for the intervention’s sustainability.`,
        },
        challenges: {
            type: "text",
            text: `Adolescent girls often shift back to their families during childbirth, thus potentially limiting exposure to the intervention. The community’s acceptance of Accredited social health activists (ASHAs), remains slow and uneven.`,
        },
        lessons: {
            type: "bullets",
            bullets: [
                `Community health workers can play a central role in reaching vulnerable and marginalized populations.`,
                `Training and mentoring accredited social health activists (ASHAs) to assess girls’ health needs and undertake monthly morbidity surveillance is critical, as are needs-specific behaviour change communication (BCC) approaches and tools to interact with girls and their families. These interactions improve the use of maternal care services. Overall, the largely successful BCC interventions reached girls and enabled adolescents to access care and self-report.`,
                `Oversight mechanisms such as Community-based Village Health, Sanitation and Nutrition Committees (VHSNCs) in rural areas and Women Health Committees (MAS)are important for monitoring and identifying implementation gaps.`,
                `Partnerships between the Government and NGOs allow for improved health-care processes such as ASHAs’ performance and reach, leveraging of BCC resources and integration of information management systems.`
            ],
        },
        authors: [
            `Alka Barua, Independent Consultant, India`,
            `Swati Shinde, Independent Consultant, India`,
            `Ashok Dyalchand, Director, IHMP, India`,
            `Manisha Khale, Additional Director, IHMP, India`,
            `Venkatraman Chandra Mouli, Scientist, Department of Sexual and Reproductive Health and Research, World Health Organization/Human Reproduction Program`,
        ],
        getInTouch: `alki75@hotmail.com`,
        sources: [
            `Dyalchand A. Efficacy of an intervention for improving the reproductive and sexual health of married adolescent girls and addressing the adverse consequences of early motherhood. Lancet Glob Health. 2014;2(S12). doi:10.1016/S2214-109X(15)70034-3.`,
            `Dyalchand A, Pande RP, Kulkarni G, Khale M. Improving the use of maternal care services among married adolescent girls: an intervention study in rural India. J  Biosoc Sci. 2021;53(3):336–55. doi:10.1017/S0021932020000206.`,
            `Health and family welfare statistics in India 2019–20. New Delhi: Government of India; 2020 (https://main.mohfw.gov.in/sites/default/files/HealthandFamilyWelfarestatisticsinIndia201920.pdf, accessed 5 December 2021).`,
            `Fulpagare PH, Saraswat A, Dinachandra K, Surani N, Parhi RN, Bhattacharjee S, et al. Antenatal care service utilization among adolescent pregnant women–evidence from Swabhimaan Programme in India. Front Public Health. 2019;7:369. doi:10.3389/fpubh.2019.00369.`,
            `Khan A. Promoting, safe behaviours, sexual and reproductive health and gender equal attitudes in young married couples in Maharashtra. Report of the Evaluation conducted by UNFPA. New York: UNFPA; 2006.`,
            `Maternal health. In: UNICEF [website] (https://www.unicef.org/india/what-we-do/maternal-health, accessed 5 December 2021).`,
            `Special bulletin on maternal mortality in India (2016–18). New Delhi: Office of the Registrar General India (https://censusindia.gov.in/vital_statistics/SRS_Bulletins/MMR%20Bulletin%202016-18.pdf, accessed 5 December 2021).`,
        ]
    },
    {
        id: "2",
        country: `Kazakhstan`,
        image: kazakhstan,
        imagealt: "This picture reflects the context of the story, strengthening policies and legislation for better adolescent health in Kazakhstan",
        pdf: "/pdfs/Kazakhstan - Advancing adolescent health through policy and legislation D1.pdf",
        pdfs: [
            {
                url: "/pdfs/Kazakhstan - Advancing adolescent health through policy and legislation D1.pdf",
                language: "English",
            },
            {
                url: "/pdfs/Kazakhstan - Advancing adolescent health through policy and legislation D1 RUSSIAN.pdf",
                language: "Russian",
            }
        ],
        imageCredit: `© UNFPA Kazakhstan`,
        header: `Advancing adolescent health through policy and legislation`,
        sixtySecondsSummary: `Kazakhstan’s 2025 Health Development Policy prioritizes strengthening youth-friendly health centres (YFHCs). A package of new regulations was developed and centred on increasing coverage and access to adolescent sexual and reproductive health. With support from the United Nations Population Fund, the Ministry of Health also created a legal framework, developed clinical and operational standards, institutionalized YHFCs while strengthening their capacity. Interventions also seek to generate demand for the services, and develop communication tools. Adolescents have access to SRH at the primary health-care level, including outpatient access to youth-friendly health services.`,
        sexualAndReproductiveHealthChallenge: `The 2025 Health Development Policy prioritizes strengthening youth-friendly health centers (YFHCs) in Kazakhstan. The Government of Kazakhstan further committed to YFHCs during the 2019 International Conference on Population and Development (Nairobi Summit, ICPD25). Access to youth-friendly health services in Kazakhstan is limited. There are 128 YFHCs located primarily in large cities and servicing no more than 1% of the total population aged 14–28 annually. Furthermore, the services provided in YFHCs are of uneven quality, not standardized, not clearly defined in the budget-funded, state-guaranteed benefit package, and financed based on the regional authorities’ capability.
    In 2015, youth-friendly health services were privatized. The Healthy Lifestyle Promotion Centres under which they were placed, were deemed unprofitable. The mandatory social health insurance (MSHI) introduced in Kazakhstan from 2020 did not cover youth-friendly health services.`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
The Government of Kazakhstan embarked on a multi-pronged approach to improve adolescent health. There have been concerted efforts to implement the World Health Organization's recommendations on “developing national quality standards for adolescent-friendly health services” into the national health-care system and in the list of services of the mandatory social health insurance (MSHI). To implement these standards, the United Nations Population Fund (UNFPA) supported Kazakhstan’s Ministry of Health (MOH) to create a legal framework, develop clinical and operational standards, institutionalize YFHCs, strengthen their capacity, generate demand for the services, and develop communication tools. 
A key component of this effort was the development of a comprehensive legal framework during 2020–2021. <br/><br/> The Government amended the legislation to address existing gaps in regulations on adolescents’ rights and parental and health workers’ responsibilities, as well as to support access to adolescent health and expand its coverage.
    In 2020, Parliament amended the National Code “On population health and the healthcare system”, considered as Law, to:
Include the right of minors to receive information on reproductive health.
    Lower the age of consent to receive outpatient services without parental consent from 18 to 16.
Grant authority to the Ministry of Health to approve how to provide YHFSs. More specifically, the Ministry of Health must develop and approve specific subsidiary rules on how to organize the services and the types of services the YFHCs should provide.
    Additionally, during 2020-2021, the Ministry of Health approved a package of subsidiary legislations to support the National Health Code, including a mandatory requirement to establish YFHCs in each city, national standards for YFHCs, and inclusion of youth-friendly health services in MSHI coverage, among others.`,
        sexualAndReproductiveHealthUniversalHealthCoverageOutcomes: `Kazakhstan’s package of new regulations and legislation for youth-friendly health services and YFHCs is increasing coverage of and access to health-care services for adolescents.`,
        success: {
            type: "bullets",
            header: `As a result of these advances, the following have been achieved:`,
            bullets: [
                `Outpatient youth-friendly health services are available for minors from the age of 10 with parental consent, while prevention and diagnostic treatment for minors from the age of 16 is available without parental consent (except for abortions and surgical procedures).`,
                `Youth-friendly health services include laboratory tests (for HIV, sexually transmitted infections and hormone levels), physician’s examinations (gynaecologists and urologists), ultrasound visualization, psychological counselling, and social and paralegal support in the case of unintended pregnancies, sexual or gender-based violence, and suicidal behaviour.`,
                `Mandatory social health insurance (MSHI) services now include youth-friendly health services and the State covers the insurance premium for minors and youth over 18 studying full-time.`,
                `With effect from 2021, MSHI began directly contracting YFHCs and purchasing their services through a fee-for-service method.`,
                `UNFPA provided training to around 300 professionals (physicians, psychologists and social workers) working in YFHCs following the modus operandi of the YFHCs based on unified national standards.`,

            ]
        },
        challenges: {
            type: "text",
            text: `There is a need to continue advocacy efforts among parents, society and young people on the availability of youth-friendly health services to strengthen the reproductive health of the younger generation.`
        },
        lessons: {
            type: "bullets",
            bullets: [
                `An enabling policy and legislative environment is important for advancing adolescent health coverage and uptake – for example, lowering the age of consent for youth to access health-care services without parental consent.`,
                `At the policy and implementation level, clearly outlining a list of youth-friendly health services is necessary for the mandatory health insurance and health benefits package.`,
                `The adolescent health agenda depends on integrating financing arrangements, such as by drawing on the contracting model for youth-friendly health services in Kazakhstan.`,

            ]
        },
        authors: [
            `Dr Serik Tanirbergenov, UNFPA Country Office, Kazakhstan`,
            `Dr Gaukhar Abuova, UNFPA Country Office, Kazakhstan`,
            `Dr Azhar Giniyat, The Ministry of Healthcare, Kazakhstan`,
        ],
        getInTouch: `tanirbergenov@unfpa.org`,
        sources: [
            `ASRH website [developed based on a standardized solution mHealth Starter Pack by request of the Ministry of Health of the Republic of Kazakhstan with the technical and financial support of UNFPA] (in Kazakh) (https://shyn.kz/, accessed 4 December 2021).`,
            `Ministry of Health of the Republic of Kazakhstan, United Nations Population Fund (UNFPA). Sociological survey on the status of reproductive health of adolescents and young people aged 15–19, their sexual behaviour and access to reproductive health services and information. New York: UNFPA; 2018 (https://kazakhstan.unfpa.org/sites/default/files/pub-pdf/ENG_Pager__Reproduct_03-2019__new.pdf, accessed 4 December 2021).`,
            `“On the health of the people and the healthcare system” the Code (law act) of the Republic of Kazakhstan dated July 7, 2020, No. 360-VI ЗРК. Articles 78 (para 2), 92. Nur-Sultan: Government of Kazakhstan; 2020 (in Kazakh) (http://adilet.zan.kz/rus/docs/K2000000360, accessed 4 December 2021).`,
            `Strategy on developing of the resource Youth-Friendly Health Center in the Republic of Kazakhstan for 2020–2022. Nur-Sultan: Ministry of Health Kazakhstan; 2019 (in Kazakh) (https://bit.ly/3xJPJwS, accessed 4 December 2021).`,
            `“The rules for payment for services of healthcare entities within the State guaranteed benefit package and (or) in the System of mandatory social health insurance”. Order of the MoH dated December 20, 2020, No. ҚР DSM-291/2020. Appendix 20. Nur-Sultan: Government of Kazakhstan; 2020 (in Kazakh) (http://adilet.zan.kz/rus/docs/V2000021831, accessed 4 December 2021).`,
            `“The rules for the organization of medical care for the protection of the reproductive and mental health of minors aged ten to eighteen years old and youth”. Order of the Minister of Health of the Republic of Kazakhstan dated December 20, 2020, No. ҚР DSM-285/2020/. Nur-Sultan: Government of Kazakhstan; 2020 (in Kazakh) (http://adilet.zan.kz/rus/docs/V2000021846, accessed 4 December 2021).`,
            `The State Program for Healthcare Development for 2020–2025, approved by the Resolution of the Government of the Republic of Kazakhstan dated December 26, 2019, No. 982. Nur-Sultan: Government of Kazakhstan; 2019 (in Kazakh)  (http://adilet.zan.kz/rus/docs/P1900000982/ https://kazakhstan.unfpa.org/sites/default/files/pub-pdf/ENG_%D0%A1%D0%A2%D0%A0%D0%90%D0%A2%D0%95%D0%93%D0%98%D0%AF%20--%2025%20%281%29.pdf, accessed 4 December 2021).`,
        ]
    },
    {
        id: "3",
        country: `Malawi`,
        image: malawi,
        imagealt: "This picture reflects the context of the story, aligning donor priorities and funds for services such as maternal health in Malawi",
        pdf: "/pdfs/Malawi - Aligning donor and government sexual and reproductive health financing and priority setting D1.pdf",
        imageCredit: `© DFID`,
        header: `Aligning donor and government sexual and reproductive health financing and priority setting`,
        sixtySecondsSummary: `The Malawi Ministry of Health developed an Operational Plan for the annual Health Sector Strategic Plan II to strengthen aid coordination, donor alignment and government financing for sexual and reproductive health (SRH) and universal health coverage. The plan integrates planning information across the health sector, providing a consolidated and updated list of unfunded health sector priorities to partners to guide their investments. SRH comprises of 10% of the total activity costs. Government and donor alignment must include more granular alignment against specific costed SRH activities, and partner activities must be tailored to each district’s SRH context and priorities.`,
        sexualAndReproductiveHealthChallenge: `To shift the country towards achieving universal health coverage (UHC), the Government of Malawi has developed the Health Sector Strategic Plan II (HSSP II) for 2017–2022, which defines which SRH services should be provided to the population for free as part of the Essential Health Package.
    In Malawi, donors provide 77% of health funding and nearly 99% of all SRH funding. The donor landscape is fragmented, with 115 financing sources and 227 implementing partners, leading to inefficiencies and difficulties in aligning and tracking programming. The challenge is further compounded by fragmented and overlapping strategic plans, which hinders the clear identification of sector-wide priorities for UHC and SRH.
    Furthermore, priorities are often set at the national level without adequate consultation with district health management teams, leading to SRH programmes that are poorly aligned to each district’s specific SRH context and priorities.`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
To strengthen aid coordination for SRH and UHC, the Ministry of Health (MOH) developed the annual Health Sector Strategic Plan II (HSSP II), Operational Plan with technical support from the Clinton Health Access Initiative (CHAI). The Operational Plan aims to integrate planning information across the sector and provide a consolidated, routinely updated list of unfunded health sector priorities to help guide partner investments. SRH is a prominent component of the plan, representing 10% of the total activity costs.
    The HSSP II Operational Plan consolidates 43 data sources and over 37000 activities at the national and district levels to provide a comprehensive overview of health sector priorities and funding gaps. All activities are mapped and consolidated into a single consistent framework and database, including linkage to the HSSP II objectives, the geography of implementation, funding status, cost and priority status for unfunded activities. Launched in 2020, subsequent revisions by the Ministry of Health (MOH) and Clinton Health Access Initiative (CHAI) staff have been released annually. A dissemination road map is developed to ensure that results are subsequently shared with relevant government and donor stakeholders.`,
        success: {
            type: "bullets",
            bullets: [
                `By fully integrating SRH into the broader, UHC-oriented Health Sector Strategic Plan II (HSSP II) Operational Plan, the Ministry of Health (MOH) has reduced the fragmentation in the planning, coordination and decision-making processes for Malawi’s scarce financial resources.`,
                `The HSSP II Operational Plan quantified US$ 739 million in unfunded activities for the health sector, including US$ 54 million in unfunded activities specifically for SRH. As a result, the MOH Reproductive Health Directorate can now leverage the HSSP II Operational Plan to support the coordination of its many partners, rather than relying on siloed, vertically driven coordination structures that are not clearly embedded within the broader UHC agenda.`,
                `Ensuring that District Implementation Plans are fully integrated into the Operational Plan facilitates national-level visibility into each district’s unique SRH priorities and funding gaps, thereby increasing the likelihood that future SRH partner activities will be better tailored to each district’s context and within decentralized frameworks.`,
            ]
        },
        challenges: {
            type: "bullets",
            "bullets": [
                `There is a need to continuously ensure the uptake of the HSSP II Operational Plan into routine decision-making by SRH development partners. To mitigate the challenge, the MOH will continue to map partner funding cycles and conduct bilateral engagements with key SRH partners, to identify entry points and inform partner decision-making around resource allocation for SRH and UHC.`,
            ]
        },
        lessons: {
            type: "bullets",
            bullets: [
                `Government and donor alignment must go beyond consensus on high-level objectives and include more granular alignment against specific costed SRH activities, especially at the district level to ensure partner activities are tailored to each district’s specific SRH context and priorities.`,
                `Fragmented and overlapping strategic plans for SRH, UHC and other vertical programmes make it difficult to identify overall priorities clearly. This is temporarily addressed by consolidating all sub-sectoral plans into the HSSP II Operational Plan. However, reducing the fragmentation of strategic plans moving forward and ensuring that all health systems and programmatic priorities are well reflected in the forthcoming HSSP III will be critical for ensuring strategic clarity in the health sector.`,

            ]
        },
        authors: [
            `Stephanie Heung, Clinton Health Access Initiative`,
            `Jean Nyondo, Ministry of Health, Department of Planning and Policy Development, Malawi`,
            `Kenasi Kasinje, Ministry of Health, Department of Planning and Policy Development, Malawi`,
            `Andreas Kees, Clinton Health Access Initiative`,
            `Purava Joshi, Clinton Health Access Initiative`,
            `Florence Jumbe, Ministry of Health, Department of Planning and Policy Development, Malawi`,
            `Pius Nakoma, Ministry of Health, Department of Planning and Policy Development Malawi & Global Financing Facility`,
            `John Borrazzo, Global Financing Facility`,
            `Mihereteab Teshome, Clinton Health Access Initiative`,
            `Eoghan Brady, Clinton Health Access Initiative`,
            `Andrews Gunda, Clinton Health Access Initiative`,
            `Gerald Manthalu, Ministry of Health, Department of Planning and Policy Development, Malawi`,
            `Emily Chirwa Ministry of Health, Department of Planning and Policy Development, Malawi`,
        ],
        getInTouch: `sheung@clintonhealthaccess.org`,
        sources: [
            `Guidelines for district health planning 2018–2022. Lilongwe: Government of Malawi; 2018.`,
            `Health sector resource mapping round 6 report. Lilongwe: Government of Malawi; 2020.`,
            `Health Sector Strategic Plan II. Lilongwe: Government of Malawi; 2017.`,
            `Health Sector Strategic Plan II operational plan for financial year 2019–2020. Lilongwe: Government of Malawi; 2020.`,
            `Health Sector Strategic Plan II operational plan for financial year 2020–2021. Lilongwe: Government of Malawi; 2021.`,
            `Investment Case for Reproductive, Maternal, Newborn, Child and Adolescent Health and Nutrition 2020–2022. Lilongwe: Government of Malawi; 2019.`,
            `National Sexual and Reproductive Health and Rights Strategy 2021–2025. Lilongwe: Government of Malawi; 2018.`,
            `The mid-term review of the Malawi Health Sector Strategic Plan II. Lilongwe: Government of Malawi; 2021.`,
        ]

    },
    {
        id: "4",
        image: mexico,
        imagealt: "This picture reflects the context of the story, a waiting room for health services for indigenous communities in Mexico",
        pdf: "/pdfs/Mexico - Increasing access to obstetric care for indigenous women through a non-governmental model of care D2.pdf",
        pdfs: [
            {
                url: "/pdfs/Mexico - Increasing access to obstetric care for indigenous women through a non-governmental model of care D2.pdf",
                language: "English",
            },
            {
                url: "/pdfs/Mexico - Increasing access to obstetric care for indigenous women through a non-governmental model of care D1 SPANISH.pdf",
                language: "Spanish",
            }
        ],
        country: "Mexico",
        header: "Increasing access to obstetric care for indigenous women through a non-governmental model of care",
        imageCredit: "© WHO - TDR / Isaias Montilla",
        sixtySecondsSummary: `A nongovernment organization (NGO) model of care implemented by Partners in Health in
Chiapas State in Mexico is a public–private venture that provides maternal services in public
units. It includes women volunteers from the community who serve as companions to
support mothers, and a maternal health committee that responds to obstetric emergencies.
    This respectful childbirth model enables women to make choices throughout the
pregnancy–childbirth–postpartum process and promotes alternative practices to the
traditional model of care. Partnerships with NGOs are beneficial in providing sexual and
reproductive health services to hard-to-reach populations such as indigenous women`,
        sexualAndReproductiveHealthChallenge: `
Providing access to health-care services for vulnerable populations is a priority within the
universal health coverage (UHC) agenda. In Mexico, 77% of maternal deaths occur among
indigenous women in Chiapas. Evidence shows that various factors – including cultural
barriers – hinder indigenous populations’ access to health-care services.
    The Government of Mexico is implementing a UHC strategy to expand public insurance and
financing for health centers and hospitals. The National Centre for Gender Equity and
Reproductive Health promotes good practices for pregnancy, childbirth and post-natal care
and supports a patient-centered model of care; however, progress has been slow.`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
Two Mexican states were selected as intervention sites for two different models of obstetric
care. Rural and indigenous communities in Oaxaca were selected for the standard
(government) model of care and a nongovernmental organization (NGO) model of care was
implemented in Chiapas.
    The standard model of care delivers services at the primary care level through community
health centers. The number and type of healthcare professionals are dependent on the
population in each town. The standard model includes health posts – a medicine dispensary
run by community health workers and mobile medical units. At the community level, health
workers, midwives and special project brigades identify pregnant women for antenatal
    consultations.
        The NGO model – implemented by Partners in Health – is a public–private venture that
provides services in public units. It includes women volunteers from the community who
serve as companions to support mothers, and a maternal health committee responds to
obstetric emergencies. It provides ambulatory services, medication and trained personnel to
supervise technical aspects of care.
    For both models, women must pay to travel 30–70 kilometres to access secondary level of
care (hospitals). The hospitals provide ambulances in cases of obstetric emergencies when
they are available.`,
        sexualAndReproductiveHealthUniversalHealthCoverageOutcomes: `
In the standard model, 15% of women do not initiate antenatal care (ANC) during the first
trimester of pregnancy and 28% report complications during pregnancy. Women did not
initiate timely care due to economic, language and cultural barriers, and did not get to make
their own decisions. In the NGO model, 98% of women began ANC in their first trimester and
29% had deliveries managed at home accompanied by midwives, health companions and
obstetric nurses.
    The main distinction between the two models is that the NGO model takes a rights-centred
approach that emphasizes freedom of choice and allows women to choose their labour
position and place of delivery. Women valued freedom of choice, felt they had adequate
information and could make informed choices about other SRH services, such as family
planning.
    Health-care workers reported a lack of equipment in both the NGO model (86.2%) and the
government model (55.5%), and women generally perceived the NGO model to be better.
    Travel time to secondary level of care in both models were similar. However, in the NGO
model, companions supported the process. The percentage of reported mistreatment was
higher in the standard model (28%) than the NGO model (15%). Both provide a place where
women and families can wait during childbirth. In the NGO model, accommodation is offered
to companions whereas in the standard model it is only offered to women from remote
communities, and is insufficient.`,
        success: {
            type: "bullets",
            bullets: [
                `The NGO model offers women freedom of choice for the type of provider, facility and
birth they prefer, translating to better sexual and reproductive health outcomes, such
as family planning choices.`,
                `In this model, health companions support women through counselling and assistance
with referrals, and women perceive it as a better experience.`,
                `The respectful childbirth model enables women to make choices throughout the
pregnancy–childbirth–postpartum process and promotes alternative practices to the
traditional model, based on scientific evidence.`,
            ]
        },
        challenges: {
            type: "bullets",
            bullets: [
                `Both models face challenges e.g., referral systems, waiting times, and transportation
costs for women to access secondary-level care.`,
                `Due to language and cultural barriers, health-care professionals struggle to
encourage indigenous women to access ANC and other SRH services.`,
            ]
        },
        lessons: {
            type: "bullets",
            bullets: [
                `The primary health-care level must be strengthened with skilled professionals, better
infrastructure, equipment and supplies to meet the SRH needs of vulnerable
populations.`,
                `To attain universal coverage of SRH services, the focus must be on women, with
    movement away from the medicalized approach to a rights-centered approach.`,

                `Partnerships with NGOs may be beneficial in providing sexual and reproductive
health services to hard-to-reach populations such as indigenous women.`,
            ]
        },
        authors: [
            `Clara Juárez-Ramírez, Researcher, Centre for Health Systems Research, National Institute of Public Health, México City, México`,
            `Gustavo Nigenda, Professor, National School of Nursing and Obstetrics, National Autonomous University of Mexico, Mexico City, Mexico`,
            `Alma L. Sauceda-Valenzuela &amp; Aremis Villalobos, Researcher, Centre for Health Systems Research, National Institute of Public Health, México City, México`,
        ],
        getInTouch: `gnigenda@outlook.com`,
        sources: [
            `Juárez-Ramírez C, Nigenda G, Sauceda-Valenzuela AL, Villalobos A. Lags in the provision
of obstetric services to indigenous women and their implications for universal access to
healthcare in Mexico. Sex Reprod Health Matters. 2020;28(2):1778153,
    doi:10.1080/26410397.2020.1778153.`
        ],
    },
    {
        id: "5",
        image: nepalChw,
        imagealt: "This picture reflects the context of the story, women waiting for health services in Nepal",
        pdf: "/pdfs/Nepal - Community health workers expand access to sexual and reproductive health services in rural areas D1.pdf",
        country: "Nepal",
        header: "Community health workers expand access to sexual and reproductive health services  in rural areas",
        imageCredit: "© WHO / Tom Pietrasik",
        sixtySecondsSummary: `In rural Nepal, local community health workers (CHWs) are trained and deployed to deliver
sexual and reproductive health (SRH) services and information to women in their
communities. Using individualized birth control counselling, a series of questions, and visual
aids, CHWs explain different contraceptive methods and help women narrow down their
choices consistent with their reproductive values and preferences. CHWs are trained in how
to involve mothers-in-law and partners, leveraging their decision-making power over
women’s health. They also play an important role in data collection – for example, on
    contraceptive use and preferences`,
        sexualAndReproductiveHealthChallenge: `
Expanding access to contraception is vital for ensuring a woman’s ability to decide how
many children she wants and when she wants to have them. Women’s SRH needs in the
postpartum period are often neglected and in Nepal, 52% of women have an unmet need for
    contraception in the 24 months following delivery; this is especially the case for women in
    the rural areas. While 95% of women want to delay pregnancy for at least two years, only
39% report using modern contraception. The expansion of access to SRH services is
necessary to achieve universal health coverage (UHC). This pilot intervention led by the
Government of Nepal and civil society deploys community health workers (CHWs) to
achieve UHC of SRH services among hard-to-reach populations`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
Historically in Nepal, community health workers (CHWs) who are female community health
volunteers (FCHVs) work up to two days a week without compensation, serving a population
of 500 patients within their communities. In collaboration with the Ministry of Health and
Population, nongovernmental organization (NGO) Nyaya Health Nepal and Possible – a U.S.
    non-profit organization – have professionalized this model and lowered the CHW-to-
population ratio to one per 2000 individuals. Unlike the FCHVs, these CHWs are full-time,
    salaried and supervise local women with minimum 10th-grade education. They provide
person-centred contraceptive counselling embedded within an integrated longitudinal CHW
intervention for active pregnancy surveillance, home-based antenatal care, postnatal care
and infant care.

    The CHWs deliver person-centred, high-quality, individualized contraceptive counselling
using an adapted Balanced Counselling Strategy (BCS) toolkit developed by the Population
Council. Using a series of questions, they help women to narrow down their contraceptive
choices consistent with their reproductive values and preferences; visual aids are also used
to guide counselling and illustrate different contraceptive methods. Culturally tailored training
sessions for CHWs include; how to involve mothers-in-law and partners, given their decision-
making power over women’s health. CHWs conduct regular follow-up home visits and refer
women who desire contraception to the nearest health facilities. CommCare, an open-source
platform, enables a customized mobile app, which guides CHWs in decision-making,
    collecting data offline, and displaying counselling content.`,
        success: {
            type: "bullets",
            bullets: [
                `In rural Nepal, the intervention resulted in 79% of women receiving counselling at
least once during their postpartum period. The use of postpartum modern
contraception method increased from 29% pre-intervention to 46% post-intervention.`,
                `The increase in the use of modern contraceptives was primarily due to an increase in
the use of the lactational amenorrhoea method. One-year post-intervention, women
were nearly two times more likely to use modern contraceptives in the early
postpartum period (0–5 months). In the late postpartum period (6–11 months), the
use of modern contraceptives increased from 16% to 30%, with most of the increase
seen in the use of injectables.`,
                `Beneficiaries reported that Community health workers (CHWs) played a role in
increasing knowledge of contraceptive methods, including educating women, their
partners and mothers-in-law on the benefits and side effects of each method. Women
reported having better knowledge and understanding to make decisions, leading to
increased demand for contraception. Several beneficiaries stated that they prefer
receiving counselling at home instead of at the health facility.`
            ]
        },
        challenges: {
            type: "bullets",
            bullets: [
                `Factors that continue to influence women’s contraceptive use include concern for
    side effects and needing to involve partners and mothers-in-law.`,
                ` Community health workers (CHWs) do not distribute contraceptives, women wishing
to receive contraceptives are referred to health facilities that may be up to 3–5 hours
away.`
            ]
        },

        lessons: {
            type: "bullets",
            bullets: [
                `Longitudinal integrated projects engaging CHWs and using mobile technology have
the potential to expand SRH service coverage to hard-to-reach populations.`,
                `The extension and contextualization of these services to women’s partners and
mothers-in-law who hold decision-making power over their health is important in
increasing contraception utilization.`,
                `The integration of CHWs into the health-care system is effective in providing SRH
services to women. CHWs also play an important role in data collection – for
    example, on contraceptive use and preferences.`,
                `The pilot provides lessons for the Government to strengthen its female community
health volunteers (FCHV) model; for example, by paying a salary, increasing
supervision and implementing lower CHW-to-population ratios.`
            ]
        },
        authors: [
            `Wan-Ju Wu, Possible; Boston Medical Center, Department of Obstetrics and Gynecology, USA`,
            `Aparna Tiwari, Nyaya Health Nepal`,
            `Nandini Choudhury, Possible; Icahn School of Medicine at Mount Sinai, Arnhold Institute for Global Health, USA`,
            `Indira Basnett, Ministry of Health and Population, Nepal Health Sector Support Programme`,
            `Rita Bhatt, Nyaya Health Nepal`,
            `David Citrin, Possible; Departments of Global Health and Anthropology, Henry M. Jackson School of International Studies, University of Washington, USA`,
            `Scott Halliday, Possible; Icahn School of Medicine at Mount Sinai, Arnhold Institute for Global Health; Department of Global Health, Henry M. Jackson School of International Studies, University of Washington, USA`,
            `Lal Kunwar, Nyaya Health Nepal`,
            `Duncan Maru, Possible; Icahn School of Medicine at Mount Sinai, Departments of Internal Medicine and Pediatrics, Arnhold Institute for Global Health, USA`,
            `Isha Nirola, Harvard T.H. Chan School of Public Health, USA`,
            `Sachit Pandey, Nyaya Health Nepal`,
            `Hari Jung Rayamazi, Nyaya Health Nepal`,
            `Sabitri Sapkota, Nyaya Health Nepal`,
            `Sita Saud, Nyaya Health Nepal`,
            `Aradhana Thapa, Nyaya Health Nepal`,
            `Alisa Goldberg, Brigham and Women’s Hospital, Department of Obstetrics, Gynecology and Reproductive Biology; Harvard Medical School; Planned Parenthood League of Massachusetts, USA`,
            `Sheela Maru, Possible; Icahn School of Medicine at Mount Sinai, Department of Obstetrics,  Gynecology, and Reproductive Science, Arnhold Institute for Global Health, USA`,
        ],
        getInTouch: `sabitri.sapkota@possiblehealth.org`,
        sources: [
            `Wu W-J, Tiwari A, Choudhury N, Basnett I, Bhatt R, Citrin D, et al. Community-based
postpartum contraceptive counselling in rural Nepal: a mixed-methods evaluation. Sex
Reprod Health Matters. 2020;28(2):1765646. doi:10.1080/26410397.2020.1765646.`
        ],
    },
    {
        id: "6",
        image: nepalSac,
        imagealt: "This picture reflects the context of the story, providing sexual and reproductive health services, for example a female health worker explaining health services to women in Nepal",
        country: "Nepal",
        pdf: "/pdfs/Nepal - Strategic partnerships between Ministry of Health and civil society towards improved access to safe abortion care D1.pdf",
        header: `Strategic partnerships between Ministry of Health and civil society towards improved access to safe abortion care`,
        imageCredit: `© IPPF`,
        sixtySecondsSummary: `
Safe abortion care (SAC) has been integrated into the Basic Health Service Package
(BHSP) in Nepal. Civil society organizations engaged policy-makers, citing evidence on the
importance of SAC in addressing maternal deaths. Task-shifting from obstetrician-
gynaecologists at hospitals to nurses/midwives who operate at health posts at the
community level helped to address the shortage of specialized health-care professionals and
to improve equity in access to health care. In 2016, the Government allocated an annual
budget to implement “Free Safe Abortion Services”, removing financial barriers to accessing
SAC at public health facilities.`,
        sexualAndReproductiveHealthChallenge: `
Abortion has been legal in Nepal since 2002. The 2018 Safe Motherhood and Reproductive
Health Rights Act provides for women who request an abortion up to 12 weeks of gestation
and up to 28 weeks in cases of rape, incest, a threat to the physical or mental well-being of
the mother, or fetal abnormality. Universal health coverage (UHC) is a key pillar in the
2015–2020 Nepal Health Sector Strategy. The 2016–2021 Implementation Plan
operationalizes the roll-out of UHC in Nepal, including a draft Basic Health Service Package
(BHSP) centered on increasing access to health-care services. Nepal has taken important
steps to integrate safe abortion care (SAC) into the country’s UHC programming – for
    example, through the BHSP.`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
The Government of Nepal, in partnership with international nongovernmental organization
(NGO) Ipas and local civil society organizations (CSOs), worked towards integrating SAC
into the standard package of UHC services. They drove the successful prioritization and
implementation of SAC through three main mechanisms: dialogue or engagement, using
evidence, and task-shifting. Formal and informal meetings on SAC were held with policy-
makers at different levels of government, including the Ministry of Health and Population
(MoHP) and the Family Welfare Division (FWD).

    Citing evidence and specifically the impact of unsafe abortions on maternal deaths in Nepal
(an estimated 7%), civil society advocated for the prioritization and inclusion of SAC within
the BHSP. Additionally, task-shifting from obstetrician-gynaecologists at hospitals to auxiliary
nurse-midwives who operate at health posts at the community level was used as a
mechanism to address the shortage of specialized health-care professionals and to improve
equity in access to health care.`,
        success: {
            type: "text",
            text: `Multiple stakeholders were willing to engage on the issue, leading to adjustment of the Basic
Health Service Package (BHSP) to include free SAC up to 12 weeks at public health
facilities. In 2016, the Government allocated an annual budget to the MoHP and other
stakeholders to implement “Free Safe Abortion Services”, removing financial barriers to
accessing SAC at public health facilities. These changes were reflected in the Safe Abortion
Implementation Guidelines. In 2019, the Abortion Law was updated to ensure funding for
    supporting free SAC. Post-abortion care is now part of BHSP and free at the point of service
delivery.
    General trends show that between 2011 and 2016, there was a 2% increase in the provision
of SAC, and the proportion of women who name government facilities as SAC locations
increased from 71% to 79%. The percentage of people who know that abortion is legal also
increased by 3% (from 38% to 41%). In recent years (2015–2019), the caseload of safe
abortion services at Ipas-supported government facilities increased from 16 769 to 29 952
(increase of 55%).`},
        lessons: {
            type: "bullets",
            bullets: [
                `Navigating safe abortion care (SAC) as a culturally sensitive topic required careful
management. Civil society stakeholders adopted an evidence-based approach to
engagement with policy-makers, drawing links to maternal health. Establishing
partnerships with key stakeholders in national and regional health structures for
    engagement and prioritization of sexual and reproductive health and universal health
coverage (SRH-UHC) issues is an opportunity that can be leveraged. In turn,
decision-makers should be committed to continuous engagement with civil society
stakeholders who could offer solutions – for example, task-shifting safe abortion
services to auxiliary midwives at lower health system levels. Engagement with local
    women and youth-led CSOs and health professional associations or networks is
important for developing UHC strategies.`,
                `Using evidence is important in guiding health benefits packages and other similar
policy efforts to increase access to SRH services. The emphasis on the importance
of SAC in addressing public health issues such as maternal deaths due to unsafe
abortion, and conveying the impact and need for SAC are instrumental in influencing
policy-makers’ decisions.`,
                `Financial allocation to commitments, such as to SAC, is vital for implementation and
sustainability.`,
                `CSOs are an integral component of advancing SRH–UHC through continued
accountability and implementation, including appropriate budgeting and financing of
the Basic Health Service Package; ensuring the availability of proper facilities,
    supplies and trained staff across the country; and task-shifting.`
            ]
        },
        authors: [
            `Tanvi Monga, Ipas, USA`,
            `Madhabi Bajracharya, Ipas, Nepal`,
            `Hina Aziz, Ipas, Pakistan`,
            `Lhamo Yangchen Sherpa, Ipas, Nepal`,
            `Irum Shaikh, Ipas, Pakistan`,
            `Ghulam Shabbir, Ipas, Pakistan`,
            `Popular Gentle, Ipas, Nepal`,
            `Ghazaleh Samandari, University of North Carolina, USA`,
        ],
        sources: [
            `Monga T, Bajracharya M, Aziz H, Sherpa LY, Shaikh I, Shabbir, G, et al. Increasing safe
        abortion access through universal health care: promising signs from Nepal and Pakistan.
            Sex Reprod Health Matters. 2020;28(2):1763008. doi:10.1080/26410397.2020.1763008.`
        ],
    },
    {
        id: "7",
        image: somalia,
        imagealt: "This picture reflects the context of the story, the role of communities in strengthening health care, for example maternity care in Somalia",
        pdf: "/pdfs/Somalia - Community stakeholders strengthen sexual and reproductive health services coverage D2.pdf",
        country: "Somalia",
        header: "Community stakeholders strengthen sexual and reproductive health services coverage",
        imageCredit: "Credit: © DFAT photo library",
        sixtySecondsSummary: `Somalia’s strategy of using community stakeholders, including religious leaders, to work with
    the Federal Ministry of Health (FMOH) and the International Rescue Committee (IRC) to
strengthen the delivery of SRH services is yielding results. Using advocacy, training,
    community engagement and awareness of religious leaders, SRH health messages and
birth spacing information are incorporated into spiritual teachings. Adopting an approach of
co-leadership between implementers (FMOH and IRC) and beneficiaries (religious scholars)
is essential in engaging communities and facilitating support for the intervention.`,
        sexualAndReproductiveHealthChallenge: `
Somalia reports poor health outcomes, including a high maternal mortality rate (732 per
100 000 live births), a high total fertility rate (6.272) and a low modern contraceptive
prevalence rate (26.93) (American Refugee Council, 2018). The Universal Health Coverage
Partnership (UHC-P), spearheaded by the World Health Organization (WHO), brings
together international cooperation on UHC and primary health care (PHC). The Government
of Somalia joined the UHC-P in 2018 and has since completed consultations to localize
sustainable development goals (SDGs), the UHC road map, and the Essential Package of
Health Services (EPHS) (Ministry of Health, 2020).
The Federal Ministry of Health (FMOH) Somalia has developed a comprehensive list of SRH
health services. Birth spacing is one of the three strategies the FMOH adopted as a national
priority to reduce maternal mortality. Political commitment, priority setting, and
implementation go hand in hand with the buy-in of religious communities, which play a
prominent role in approving or disapproving use of contraceptives in the communities.`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
Islam is Somalia’s primary religion; therefore, in May 2020, the Federal Ministry of Health
(FMOH) – with humanitarian aid organization the International Rescue Committee (IRC) –
began engaging the National Islamic Advisory Group. The FMOH is certifying this specific
group on advocacy training and incorporating religious teachings. They also identified and
supported 34 religious scholars from the Benadir region – where the IRC runs five health
facilities – to participate in community engagement and awareness raising around SRH and

birth spacing. There are approximately 2–3 million people in this region, including both
residents and internally displaced persons (IDPs) from neighbouring regions, and the IRC
has prioritized it for implementation of primary health services and family planning. The
training sessions in dialogue form primarily involved community engagement forums with
    women, youth groups, other religious groups, and organized/informal community groups to
demystify myths and misconceptions around SRH and birth spacing, and mobilize
communities for these services.
    The FMOH conducted dialogue sessions in multiple venues, including health facilities, and
each intervention cost approximately US$ 1400. IRC Somalia has since conducted over 200
community awareness sessions at IRC service delivery points throughout the country.
    Religious scholars co-facilitated these sessions, reaching over 5000 community members
with SRH and birth spacing information and referring them for these services at IRC service
delivery points.`,
        success: {
            type: "text",
            text: `
            As a result of the intervention, the 34 community religious scholars contributed to the
development of culturally appropriate messaging on birth spacing and have since integrated
these messages into religious conversations with community members during Friday
prayers.
    An example of one of these messages is: “Islam and The Quran do not prohibit birth control
or birth spacing, nor does it forbid a husband or wife to space pregnancies or limit their
number. Islam allows saving lives of mothers and wives so if the professional person, e.g.,
    doctors, advise, please listen and save your wife, sister, aunt or any person you may be
responsible for.”
Additionally, IRC Somalia implemented a referral system to link community engagement
work with SRH and birth spacing services. To facilitate this, IRC Somalia and the FMOH
established agreements with four public and private hospitals to increase access to SRH and
birth spacing services. Within this period, the uptake of SRH services increased from 22 279
birth spacing services provided before the intervention to 32 835 post intervention, indicating
an increase of 10 556. The religious scholars remain engaged in this intervention and
continue to be responsive, active and supportive.`
        },
        challenges: {
            type: "text",
            text: `There are promising signs of increasing uptake and coverage of SRH services through the
engagement and support of key community groups. However, once-off engagements alone
are insufficient to address deeply entrenched religious beliefs, and the onset of the COVID-
19 pandemic impacted face-to-face community engagement interventions.`
        },
        lessons: {
            type: "bullets",
            bullets: [
                `Progress towards universal health coverage depends on the implementation and roll-
out of sexual and reproductive health (SRH) services in line with shifting community-
level norms and beliefs.`,
                `Adopting an approach of co-leadership between implementers (Federal Ministry of
Health (FMOH) and Rescue Committee (IRC) and beneficiaries (religious scholars) is
important in engaging communities and facilitating support for the intervention.`,
                `It is essential to establish referral systems to ensure the provision of SRH services to
complement religious scholars integrating messaging on the importance and uptake
of birth spacing into religious activities.`,
                `The COVID-19 pandemic disrupted face-to-face initiatives, which impacted the
uptake of SRH services.`
            ]
        },
        authors: [
            `Abdullahi Askar, IRC -Mogadishu, Somalia`,
            `Mohamud Abdi Yunis – Mogadishu, Somalia`,
            `Halimasa&#39;dia Abdi, IRC – Mogadishu, Somalia`,
            `Ahmednur Ismail, IRC – Mogadishu, Somalia`,
            `Njoka Stephen Koori, IRC -Nairobi, Kenya`,
            `Andrea Edman, IRC –New York, USA`,
        ],
        getInTouch: `MohamudAbdi.Yunis@rescue.org`,
        sources: [
            `American Refugee Council. Maternal mortality in Somalia: what ARC is doing to combat
this crisis [news release]. 29 October 2018. In: reliefweb [website]
(https://reliefweb.int/report/somalia/maternal-mortality-somalia-what-arc-doing-
combat-crisis, accessed 3 December 2021).
Ministry of Health. Essential package of health services. Mogadishu: Federal Republic of
Somalia; 2020
(https://www.humanitarianresponse.info/sites/www.humanitarianresponse.info/files/documen
ts/files/ephs_2020.pdf, accessed 3 December 2021).`
        ],
    },
    {
        id: '8',
        image: pakistan,
        imagealt: "This picture reflects the context of the story, the young digital innovators standing around a poster of their technological application in Pakistan",
        pdf: "/pdfs/Pakistan - Supporting youth-led innovation for adolescent sexual and reproductive health in Pakistan D1.pdf",
        header: `Supporting youth-led innovation for adolescent sexual and reproductive health in Pakistan`,
        country: `Pakistan`,
        imageCredit: `@ (Flare of) MASHAL`,
        sixtySecondsSummary: `Five thousand young people in Pakistan have been reached with adolescent sexual and
reproductive health (ASRH) information and services via a bilingual (English and Urdu) app
launched by (Flare of) MASHAL, a youth-led organization. It provides users with information
on contraceptives, HIV, menstrual health, and other telehealth services. ASRH information
and referral pathways are also distributed through social media platforms and in-person
events, reaching close to 300 000 youth. As a result of the app’s successes, (Flare of)
MASHAL is now working with the Prime Minister of the National Youth Council to strengthen
ASRH in the country.`,
        sexualAndReproductiveHealthChallenge: `Close to 30% of the population is aged 10–24 in Pakistan (World Population Dashboard).
Pressing sexual and reproductive health (SRH) challenges include early marriage, teenage
pregnancy, unsafe abortion and low use of contraception. One of the key gaps highlighted in
the country is the lack of access to SRH information due to gender inequalities and cultural
barriers (Svanemyr et al., 2015). In 2019, youth-led organization (Flare of) MASHAL
launched a mobile and web-based app to strengthen adolescent SRH (ASRH).`,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
        The bilingual (English and Urdu) open-access mobile and web app developed provides
information on types of contraceptives, HIV, and access to sexual and reproductive health
(SRH) telehealth services. There is a one-time data cost to download the app, after which
accessing it, is free. Additional features of the app include service location map pins for free

SRH services and access to discounted SRH products, such as contraceptives, sanitary
pads and menstrual cups.
Users can also call health facilities using toll-free numbers listed on the app. Information is
tailored to the youth audience through social media campaigns, using humour, and allowing
the youth to provide feedback on materials developed. Women Deliver supported the pilot of
the intervention. Further support from the Bill and Melinda Gates Foundation and Amplify
Change led to scaling up and advocacy at a national level recognized by government
departments, the Punjab Population Innovation Fund, and the Population Welfare
Department.
        `,
        success: {
            type: "text",
            text: `Implementation of the app was initially delayed due to the need to first complete registration
for a youth-led organization to operate in the country. The sustainability of the app is
dependent on funding. Another challenge was to present the ASRH content in a culturally
sensitive manner.`,
        },
        lessons: {
            type: "bullets",
            bullets: [
                `Partnerships between government agencies and nongovernmental organizations are
                crucial to increasing ASRH service coverage. (Flare of) MASHAL demonstrates the
potential of youth-led organizations to tailor ASRH content to young people, to reach
them and provide information for referral services within the health system.`,
                `Pakistan’s Life Skills Based Education curriculum can be complemented by
leveraging technology and digital health apps, helping to increase knowledge and
engagement of adolescents and youth on SRH issues such as contraceptives and
menstrual health.`,
                `Implementing ASRH interventions continues to be challenging in culturally sensitive
environments. National-level efforts and contextual knowledge are critical to
designing and rolling out interventions.`
            ]
        },
        authors: [
            `Laraib Abid, (Flare of) MASHAL, Pakistan`,
            `Ayesham Kanwal, (Flare of) MASHAL, Pakistan`
        ],
        sources: [
            `Svanemyr J, Baig Q, Chandra-Mouli V.  Scaling up of life skills based education in Pakistan:
a case study. Sex Education. 2015;15(3):249–62. doi:10.1080/14681811.2014.1000454.`,
            `World Population Dashboard: Pakistan. In: UNFPA [website]
(https://www.unfpa.org/data/world-population/PK, accessed 3 December 2021).`,
        ]
    },
    {
        id: "9",
        country: "Zambia",
        image: zambia,
        imagealt: "This picture reflects the context of the story, providing health services for marginalized communities, for example women in Zambia",
        pdf: "/pdfs/Zambia - Bottleneck analysis for the last-mile distribution of contraceptives in Zambia D2.pdf",
        header: `Bottleneck analysis for the last-mile distribution of contraceptives in Zambia`,
        imageCredit: `© UNICEF/ Nesbitt`,
        sixtySecondsSummary: `In Zambia, a bottleneck analysis was conducted on contraceptive commodity distribution at
the point where clients access the service, which identified gaps that caused frequent
stockouts of family planning commodities. Using this information, the Ministry of Health
developed an annual action plan for contraceptives to ensure distribution in the last mile.
Interventions were rolled out; for example, the addition of vehicles to some provincial
medicine hubs. The intervention has also catalysed policy reform – the Ministry is amending
the National Drug Policy to address related emerging issues.`,
        sexualAndReproductiveHealthChallenge: `
        Improving access to a range of contraceptive methods is critical to achieving Zambia’s
national family planning goals. Zambia’s modern contraceptive prevalence rate (mCPR) is
behind the Family Planning 2020 (FP2020) goal of 58%, and there has been limited
progress in addressing teenage pregnancies and unmet needs. Sexual and reproductive
health (SRH) strategies are framed within the National Vision 2030, which recognizes
universal health coverage (UHC) as contributing to socioeconomic growth.
The Zambia Reproductive Health Policy (2008), which is currently under review, is one of
several policies that support the equitable provision of quality SRH services. However, last-
mile distribution from the central-level warehouse to the service delivery points (SDPs)
remains a key challenge in meeting SRH goals and advancing UHC.
        `,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `
        According to the 2020 Service Delivery Point Reproductive Health Commodity Security
Survey, frequent family planning commodity stockouts are reported in Zambia. Conducting a
bottleneck analysis for contraceptive commodity distribution to the last mile – the point where
clients access the service – is an important accountability mechanism. As part of the United
Kingdom Aid fund, the Women Integrated Sexual Health (WISH) (2019–2021) programme,
the Government of Zambia received support from implementing partners to monitor the
sexual reproductive and family planning (SRH/FP) budget and track expenditure against
SRH commodities.
In January 2021, the Directorate of Public Health at the Ministry of Health (MOH) in Zambia
worked with a core team consisting of the following:
<br/>
<ul>
    <li>Centre for Reproductive Health and Education (CRHE)</li>
    <li>Clinton Health Access Initiative (CHAI)</li>
    <li>John Snow, Inc. (JSI)</li>
    <li>members of the Accountability Mechanism on SRH/FP in Zambia</li>
    <li>Options Consultancy Services
    <li>Churches Health Association of Zambia (CHAZ)</li>
    <li>Global Health Supply Chain Program – Procurement and Supply Management</li>
    (GHSC-PSM)
    <li>Planned Parenthood Association of Zambia (PPAZ)</li>
    <li>United Nations Population Fund (UNFPA)</li>
    <li>Zambia Medicines and Medical Supplies Agency (ZAMMSA)</li>
</ul>
<br/>
The core team analysed the flow of contraceptive commodities to service delivery points
(SDPs), using the 2020 Reproductive Health Commodity Security Survey and the WISH-led
budget monitoring and expenditure tracking tool as reference documents. The
comprehensive bottleneck analysis highlighted the gaps that caused frequent stockouts of
family planning commodities and led to developing an annual action plan to ensure last-mile
distribution.
        `,
        success: {
            type: "text",
            text: `
            The bottleneck analysis provided valuable evidence to the Ministry of Health (MOH) to address the challenges to last-mile distribution – to increase coverage of sexual and reproductive health (SRH) services. Intragovernmental and nongovernmental stakeholders are now working together to identify and address barriers to improve SRH outcomes, strengthening coordination and accountability between stakeholders. As a result of the bottleneck analysis, the MOH has begun to roll out interventions, such as providing additional support - vehicles to three of the seven provincial medicine hubs located in Mansa, Kabompo and Mpika to increase the efficiency of distribution to the last mile. This means that seven medical hubs can conduct last-mile distribution. The Permanent Secretary advised the supply chain technical working group to form sub-committees. The sub-committees’ focus on information systems, storage and distribution, policy and guidelines has since been established. As a result, there is an increased focus on logistics data quality. The Information Systems sub-committee of the supply chain technical working group is geared towards integrating service delivery statistics utilizing the Smart Care system with logistics data using the electronic logistics management information systems. The above will enable decision-makers to identify how supply chain performance affects service delivery and make a case for further improvements to last-mile distribution. The intervention has also catalysed policy reform. The MOH is amending the National Drug Policy (1999) to include emerging issues such as supporting the growth of domestic medicine manufacture.`,
        },
        challenges: {
            type: "text",
            text: `
            One of the challenges of conducting a bottleneck analysis and action plan is the lengthy processes to access service and logistics data and differing work plans and budgeting cycles among government and nongovernment stakeholders.
            `
        },
        lessons: {
            type: "bullets",
            bullets: [
                `Performing a bottleneck analysis of the last-mile distribution is an important mechanism for conducting regular supply chain performance reviews of SRH services such as family planning. Importantly, developing an action plan based on identified gaps is important for improving SRH service delivery and coverage. Institutionalization of these mechanisms is crucial for ongoing accountability and SRH service delivery.`,
                `Multi-stakeholder partnerships continue to prove important for identifying SRH–UHC challenges and solutions to addressing gaps, such as sub-committees tasked with improving information and data quality.`,
                `The in-depth analysis of the last-mile distribution has helped strengthen the national supply chain management. Some of the findings will be part of the input to the National Drug Policy review process in Zambia. Policies and legislation provide important strategic direction. There is a need for policies to be updated to provide guidance and to reflect the current contextual challenges for SRH and UHC.`,
            ]
        },
        authors: [
            `Joy Walubita, Options Consultancy Services Limited, Zambia`
        ],
        sources: [
            `Family Planning Costed Implementation Plan (2021–2026). Lusaka: Ministry of Health Zambia [unpublished draft].`,
            `FP2020 commitment – 2020 update questionnaire: Zambia. Lusaka: Ministry of Health; 2020 (https://familyplanning2020.org/sites/default/files/Zambia_Questionnaire%20for%20Self-Report%202020_FINAL_0.pdf, accessed 4 December 2021).`,
            `Health sector supply chain strategy and implementation plan (2019–2021). Lusaka: Ministry of Health Zambia; 2019 (https://www.moh.gov.zm/?wpfb_dl=175, accessed 4 December 2021).`,
            `Reproductive Health Commodity Security Commodity Survey for service delivery point. New York: UNFPA  [unpublished draft].`,
        ]
    },
    {
        id: "10",
        country: "Pakistan",
        image: pakistan2,
        imagealt: "Value of partnerships, evidence and dialogue in increasing access to post abortion care",
        pdf: "/pdfs/Final Pakistan Story_October 2023.pdf",
        header: "Value of partnerships, evidence and dialogue in increasing access to post abortion care",
        imageCredit: `WHO / HRP / Saiyna Bashir`,
        sixtySecondsSummary: "Unsafe abortions account for 6–13% of maternal deaths in Pakistan. The amended Pakistan Penal Code allows for abortion. Against a context of wider universal health coverage (UHC) reforms including devolution of policy-making and implementation to the provincial level, the Punjab Reproductive Health Technology Assessment Committee recommended inclusion of misoprostol and a manual vacuum aspirator in the Essential Package of Health Services for safe uterine evacuation and post abortion care including post-abortion contraception. This was achieved through strengthening partnerships and dialogue among government, health professional associations, networks and civil society and using evidence on maternal deaths arising from unsafe abortions to advocate for policy and programmatic change. ",
        sexualAndReproductiveHealthChallenge: `In Pakistan, women and girls have limited access to contraceptives, contributing to 2.2 million abortions recorded in the country annually. Unsafe abortions account for 6–13% of maternal deaths in Pakistan. Before 1990, abortion in Pakistan was a punishable crime except in instances where it was a lifesaving measure. The Government amended the Pakistan Penal Code to allow for abortion. Providing post abortion care is important in meeting women and girls’ SRH needs within the universal health coverage (UHC) agenda. `,
        sexualAndReproductiveHealthUniversalHealthCoverageIntervention: `In 2011, the Ministry of Health devolved policy-making and implementation to the provincial level. Ipas Pakistan – an international nongovernmental organization (NGO) – implemented its advocacy efforts at a provincial level in Punjab. It engaged and worked with professional associations such as the Society of Obstetricians and Gynaecologists of Pakistan (SOGP), College of Physicians and Surgeons of Pakistan (CPSP), and Midwifery Association of Pakistan (MAP) and collaborated with the Ministry of National Health Services, Regulations, and Coordination. In addition, the Punjab Reproductive Health Technology Assessment Committee (PRHTAC) brought together key experts to provide guidance on best practices and post abortion services.				
        <br><br>
        Civil society organizations (CSOs) used the latest clinical evidence to shape Pakistan’s commitment towards post abortion care, including introducing use of uterine evacuation (UE) technologies in the Essential Package of Health Services (EPHS) at both provincial and national levels. Ipas Pakistan also worked with the Pakistan Nursing Council and SOGP to strengthen the national midwifery curriculum with a focus on post abortion services.						
        <br><br>
        Ipas Pakistan held values clarification workshops to ensure the respectful treatment of women and to strengthen the quality of post abortion services. In partnership with the Pakistan Alliance for Post-Abortion Care – a coalition of NGOs and government and United Nations agencies – it developed the Service Delivery Standards and Guidelines for High-Quality Safe Uterine Evacuation and Post Abortion Care and a quality assurance tool.`,
        success: {
            type: "text",
            text: `CSOs together with governments played a central role in raising stakeholder awareness and support for post abortion care. Factors that helped to improve decision-makers’ attitudes towards abortion included partnerships between government stakeholders and civil society, being open to dialogue on the issue of abortion, working within the confines of the evidence, and being sensitive to contexts. Through CSO efforts, the Punjab Reproductive Health Technology Assessment Committee recommended inclusion of misoprostol and MVA in the EPHS for safe UE and post abortion care including post-abortion contraception.
            By 2015, the Department of Health in Punjab had procured 10 million misoprostol pills with the Government’s budget. In 2016, the Drug Regulatory Authority of Pakistan included misoprostol on the National Essential Medicines List for UE or PAC. CSOs in partnership with Government trained mid-level providers in MVA.`,
        },
        challenges: {
            type: "text",
            text: ``,
        },
        lessons: {
            type: "bullets",
            bullets: [
                `Integrating SRH services such as post abortion care into the EPHS will ensure the provision of post abortion services in the long-term and will expand coverage.`,
                `There is value in multi-sectoral efforts including partnerships between civil society and governments in advancing SRHR and UHC.`,
                `The utilization of the evidence which indicates that unsafe abortion results in dire health consequences such as high levels of maternal mortality was useful in shaping the current progress on post abortion care in Pakistan.`,
                `It is important to ensure that a wide range of stakeholders are included in UHC processes, specifically health professional associations/ networks such as SOGP, CPSP and MAP given their critical role in the provision of SRH services. `
            ],
        },
        authors: [
            `Tanvi Monga, Ipas, USA`,
            `Madhabi Bajracharya, Ipas, Nepal`,
            `Hina Aziz, Ipas, Pakistan`,
            `Lhamo Yangchen Sherpa, Ipas, Nepal`,
            `Irum Shaikh, Ipas, Pakistan`,
            `Ghulam Shabbir, Ipas, Pakistan`,
            `Popular Gentle, Ipas, Nepal`,
            `Ghazaleh Samandari, University of North Carolina, USA `,
        ],
        getInTouch: ``,
        sources: [
            `Monga T, Bajracharya M, Aziz H, Sherpa LY, Shaikh I, Shabbir, G, et al. Increasing safe abortion access through universal health care: promising signs from Nepal and Pakistan. Sex Reprod Health Matters. 2020;28(2):1763008. doi:10.1080/26410397.2020.1763008.`,
            `Sathar Z, Singh S, Rashida G, et al. Induced abortions and unintended pregnancies in Pakistan. Stud Fam Plann. 2014. 45(4):471–491`,
        ]
    }
]

export const countries = Array.from(new Set(stories.map((story: IStory) => story.country)));

export const latestStories = [
    stories[0],
    stories[1],
    stories[2]
]

export const featureStory = stories[0];
