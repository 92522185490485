import React from "react";
import { StyledQuotes } from "./StyledQuotes";
import { Avatar, Container, Divider, Grid, Typography } from "@mui/material";
import { primary } from "../../common/theme";
import pascaleAllotey from "../../About/AboutTabs/images/pascale-allotey.png";
import julittaOnabanjo from "../../About/AboutTabs/images/julitta-onabanjo.jpg";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

export const Quotes = () => {
    return <StyledQuotes>
        <Container maxWidth={"lg"}>
            <Grid container sx={{position: "relative", marginTop: "50px"}}>
              <Grid item md={4} lg={3}>
                <Typography variant={"h1"} component="div" sx={{color: primary, margin: "30px 0 0", zIndex: 100, fontWeight: "700"}}>
                    There is no UHC without SRHR
                </Typography>
              </Grid>
              <Grid item md={1} className="dividerCol">
              </Grid>
              <Grid item md={7} lg={8}>
                <Typography component="p" sx={{color: primary, margin: "30px 0", zIndex: 100}}>
                  Universal health coverage (UHC) ensures that people have access to quality services without suffering financial hardship. Sexual and reproductive health and rights (SRHR) is an integral part of UHC, reaching vulnerable and marginalized populations and is a marker of achieving gender equality. The Learning by Sharing Portal (LSP) is a global resource for the integration of SRHR within UHC, covering a range of topics including digital health innovations, financing and reaching  vulnerable and marginalized populations.
                </Typography>
              </Grid>
            </Grid>


            <Grid container sx={{ color: primary, marginTop: "40px", marginBottom: "40px", justifyContent: "center" }} >
              <Grid item md={5}>
                <div className="quoteWrap" style={{ height: "100%" }}>
                  <div>
                    <div className="dropAvatar--gradient">
                      <Avatar src={pascaleAllotey} sx={{ height: "auto", width: "80%", maxWidth: "185px" }} className="dropAvatar" />
                    </div>
                    <div className="dropFlex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="68" viewBox="0 0 78 68" fill="none">
                        <path d="M77.2148 0.214844V12.2812C70.9291 13.3396 66.5291 15.5624 64.0148 18.9494C61.5006 22.3365 60.2434 28.3697 60.2434 37.049H76.272V67.2148H45.472V41.812C45.472 28.6872 47.8815 18.5261 52.7006 11.3286C57.5196 3.91942 65.691 0.214844 77.2148 0.214844ZM31.9577 0.214844V12.2812C25.672 13.3396 21.272 15.5624 18.7577 18.9494C16.2434 22.3365 14.9863 28.3697 14.9863 37.049H31.0148V67.2148H0.214844V41.812C0.214844 28.6872 2.62437 18.5261 7.44341 11.3286C12.2625 3.91942 20.4339 0.214844 31.9577 0.214844Z" fill="#E38A15"/>
                      </svg>
                      <Typography
                        className="dropQuote"
                        sx={{ marginBottom: "10px", lineHeight: "30px", fontWeight: "500", fontSize: "20px" }}
                      >
                          I cannot think of any convincing argument for why SRH services should not be part of any country’s
                          UHC package. Since the question of “why” is therefore not relevant, the portal provides a valuable
                          resource for “how” integration may be advanced
                      </Typography>
                    </div>
                  </div>
                  <div className="captionBy__wrap">
                  <Divider sx={{ width: "100%", background: "#E38A15", height: "5px", margin: "10px 0 15px "}} />
                  <span className="captionBy">
                      <h4>Dr Pascale Allotey</h4>
                      Director, WHO Department of Sexual and Reproductive Health and&nbsp;Research
                  </span>
                  </div>
                </div>
              </Grid>
              <Grid item sm={0.5}></Grid>
              <Grid item md={5}>
                <div className="quoteWrap" style={{ height: "100%" }}>
                  <div>
                    <div className="dropAvatar--gradient">
                      <Avatar src={julittaOnabanjo} sx={{ height: "auto", width: "80%", maxWidth: "185px" }} className="dropAvatar" />
                    </div>
                    <div className="dropFlex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="68" viewBox="0 0 78 68" fill="none">
                        <path d="M77.2148 0.214844V12.2812C70.9291 13.3396 66.5291 15.5624 64.0148 18.9494C61.5006 22.3365 60.2434 28.3697 60.2434 37.049H76.272V67.2148H45.472V41.812C45.472 28.6872 47.8815 18.5261 52.7006 11.3286C57.5196 3.91942 65.691 0.214844 77.2148 0.214844ZM31.9577 0.214844V12.2812C25.672 13.3396 21.272 15.5624 18.7577 18.9494C16.2434 22.3365 14.9863 28.3697 14.9863 37.049H31.0148V67.2148H0.214844V41.812C0.214844 28.6872 2.62437 18.5261 7.44341 11.3286C12.2625 3.91942 20.4339 0.214844 31.9577 0.214844Z" fill="#E38A15"/>
                      </svg>
                      <Typography
                        className="dropQuote"
                        sx={{ marginBottom: "10px", lineHeight: "30px", fontWeight: "500", fontSize: "20px" }}
                      >
                          Our call is that, when countries are defining national UHC policies
                    and plans, they must commit to and have strategies in place to
                    ensure the integration of sexual and reproductive health and rights
                      </Typography>
                    </div>
                  </div>
                  <div className="captionBy__wrap">
                  <Divider sx={{ width: "100%", background: "#E38A15", height: "5px", margin: "10px 0 15px "}} />
                  <span className="captionBy">
                      <h4>Dr Julitta Onabanjo</h4>
                      UNFPA Director for the Technical Division in New&nbsp;York
                  </span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Divider light sx={{ width: '85%', margin: "auto"}} />
        </Container>
    </StyledQuotes>;
};
