import React, { useEffect, useState } from "react";
import {storiesClasses, StyledStories} from "./StyledStories";
import {ViewContainer} from "../ViewContainer/ViewContainer";
import {StoriesCaption} from "./StoriesCaption/StoriesCaption";
import {StoriesList} from "./StoriesList/StoriesList";
import {StoriesFilter} from "./StoriesFilter/StoriesFilter";
import {Container} from "@mui/material";
import {Mode} from "../interfaces/mode";
import { countries, stories } from "./stories-items";
import { IStory } from "./interfaces/IStory";
import { useParams } from "react-router-dom";
import { useGA } from "../common/hooks";

export const Stories = () => {
    useGA("Stories");
    const { country } = useParams();
    const [innerCountry, setInnerCountry] = useState(countries[0]);
    const [innerStories, setInnerStories] = useState(stories);

    useEffect(()=> {
        if(country) {
            setInnerStories(stories.filter((story: IStory) => story.country === country));
            setInnerCountry(country)
        }
    }, [ country]);

    const handleCountryChange = (event: any) => {
        const value = event.target.value;
        setInnerCountry(value);
        if(value === "all") {
            setInnerStories(stories);
        } else {
            setInnerStories(stories.filter((story: IStory) => story.country === event.target.value));
        }
    };

    return <ViewContainer mode={Mode.Dark}>
        <StyledStories className={storiesClasses.root}>
            <StoriesCaption />
            <Container maxWidth={"lg"}>
                <StoriesList stories={innerStories} />
            </Container>
        </StyledStories>
    </ViewContainer>;
};
