import { StyledTabAbout, tabAboutClasses } from "./StyledTabAbout";
import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { primary } from "../../../common/theme";

import picture from "./images/about-new2.jpg";
import teresaSoop from "../images/teresa-soop.jpg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import React from "react";
import { BorderAllRounded } from "@mui/icons-material";


const YoutubeEmbed = ({ embedId }: any) => (
  <iframe
      width="100%"
      height="500"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube video player"
  />
);

export const TabAbout = () => {
  return (
    <StyledTabAbout className={tabAboutClasses.root}>

      <Grid container  sx={{ color: primary, marginTop: "10px", marginBottom: "70px" }} >
        <Grid item md={11}>
          <div className="quoteWrap quoteWrap--r" style={{ height: "100%" }}>
            <div className="dropFlex dropFlex--r">
              <Avatar src={teresaSoop} sx={{ height: "230px", width: "230px", maxWidth: "230px", flexGrow:"0", flexShrink:"0" }} className="dropAvatar" />
              <div>
                <div className="dropFlex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="68" viewBox="0 0 78 68" fill="none">
                    <path d="M77.2148 0.214844V12.2812C70.9291 13.3396 66.5291 15.5624 64.0148 18.9494C61.5006 22.3365 60.2434 28.3697 60.2434 37.049H76.272V67.2148H45.472V41.812C45.472 28.6872 47.8815 18.5261 52.7006 11.3286C57.5196 3.91942 65.691 0.214844 77.2148 0.214844ZM31.9577 0.214844V12.2812C25.672 13.3396 21.272 15.5624 18.7577 18.9494C16.2434 22.3365 14.9863 28.3697 14.9863 37.049H31.0148V67.2148H0.214844V41.812C0.214844 28.6872 2.62437 18.5261 7.44341 11.3286C12.2625 3.91942 20.4339 0.214844 31.9577 0.214844Z" fill="#E38A15"/>
                  </svg>
                  <div>
                    <Typography
                      className="dropQuote"
                      sx={{ marginBottom: "25px", lineHeight: "30px", fontWeight: "500", fontSize: "20px" }}
                    >
                        The SRHR-UHC portal is a powerful tool, allowing countries to learn best practices from each other through peer-to-peer learning
                    </Typography>
                    <div className="captionBy__wrap" style={{padding: "0"}}>
                      <Divider sx={{ width: "100%", background: "#E38A15", height: "5px", margin: "10px 0 15px "}} />
                      <span className="captionBy">
                          <h4>Teresa Soop</h4>
                          Senior Research Advisor for SIDA, Chair of the Governing Board of the WHO Human Reproduction Programme Governing&nbsp;Body
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container sx={{position: "relative", marginTop: "50px", marginBottom: "50px", padding: "50px 0"}} className="gradient--down gradient--down--full">
        <Grid item md={4} lg={3}>
          <Typography variant={"h1"} component="div" sx={{color: primary, fontSize:"50px", lineHeight:"60px", margin: "30px 0 0", zIndex: 100, fontWeight: "700"}}>
            Why was the LSP created?
          </Typography>
        </Grid>
        <Grid item md={1} className="dividerCol">
        </Grid>
        <Grid item md={7} lg={6} sx={{position:"relative"}}>
          <div className="sidePic--right" style={{ backgroundImage: `url(${picture})` }}></div>
          <Typography sx={{ marginBottom: "30px", marginTop: "30px" }}>
            The Sexual and Reproductive Health and Rights and Universal Health Coverage Learning by Sharing Portal (SRHR-UHC LSP) is in response to growing calls for integration and increasing access to quality sexual and reproductive health services for all populations all across the world.
          </Typography>
          <Typography sx={{ marginBottom: "30px" }}>
            The portal is a joint initiative led by the World Health Organization (WHO) and the United Nations Population Fund (UNFPA) equipping multiple stakeholders with the necessary evidence and “how to” tools to advance the SRHR-UHC integration agenda.
          </Typography>
          <Typography sx={{ color: primary, marginBottom: "30px" }} >
            The portal heeds the call of the 2019 United Nations High-Level Meeting on UHC, the WHO Human Reproduction Programme’s Governing Body (Policy Coordination Committee), national-level decision-makers, civil society and women, girls and diverse populations to urgently prioritize the integration of SRHR in wider UHC strategies and plans.
          </Typography>
        </Grid>
      </Grid>
            

      <Grid container>
        <Grid item sm={12} sx={{ marginBottom: "40px"}}>
          <Typography variant={"h1"} component="div" sx={{color: primary, margin: "40px 0", zIndex: 100, textAlign: "center"}}>
          Stakeholders share their perspectives on the importance of integrating SRHR within UHC
          </Typography>

          <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={9} sx={{ marginBottom:"10px"}}>
              <div className="videoWraps">
                <YoutubeEmbed embedId={"BfuRKuPZoAk"} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </StyledTabAbout>
  );
};
