import React, { useState } from "react";
import { StyledVideoTelling, videoTellingClasses as classes, videoTellingClasses } from "./StyledVideoTelling";
import { Mode } from "../interfaces/mode";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { VideoTellingCaption } from "./VideoTellingCaption/VideoTellingCaption";
import YouTubeModal from "../Components/YouTubeModal";
import {
    Avatar,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import { primary } from "../common/theme";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import serik from "./serik.jpg";
import picstack from './picstack.png';
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import kazakhstan from '../Stories/stories-images/kazakhstan.jpg';
import malawi from '../Stories/stories-images/malawi.jpg';
import mexico from '../Stories/stories-images/mexico.jpg';
import nepalChw from '../Stories/stories-images/nepal_chw.jpg';
import { useGA } from "../common/hooks";

interface IVideo {
    country: string;
    image: any;
    imagealt: string;
    person: string;
    title: string;
    watchTime: string;
    link: string;
    youtubeId: string;
};

const videos: IVideo[] = [
    {
        country: 'Kazakhstan',
        image: kazakhstan,
        imagealt: "This picture reflects the context of the story, strengthening policies and legislation for better adolescent health in Kazakhstan",
        person: 'Dr Serik Tanirbergenov',
        title: 'Advancing adolescent health through policy and legislation',
        watchTime: '6 min watch',
        link: 'https://youtu.be/htKx3iGj85Y',
        youtubeId: 'htKx3iGj85Y',
    },
    {
        country: 'Nepal',
        image: nepalChw,
        imagealt: "This picture reflects the context of the story, women waiting for health services in Nepal",
        person: 'Dr Sabitri Sapkota and Ms Durpata Saud',
        title: 'Community health workers expand access to sexual and reproductive health services in rural areas',
        watchTime: '8 min watch',
        link: 'https://www.youtube.com/watch?v=yV2CJFXTlgw',
        youtubeId: 'yV2CJFXTlgw',
    },
    {
        country: 'Mexico',
        image: mexico,
        imagealt: "This picture reflects the context of the story, a waiting room for health services for indigenous communities in Mexico",
        person: 'Professor Gustavo Nigenda',
        title: 'Increasing access to obstetric care for indigenous women through a non-governmental model of care',
        watchTime: '8 min watch',
        link: 'https://youtu.be/QpK1vlXbmto',
        youtubeId: 'QpK1vlXbmto',
    },
    {
        country: 'Malawi',
        image: malawi,
        imagealt: "This picture reflects the context of the story, aligning donor priorities and funds for services such as maternal health in Malawi",
        person: 'Emily Chirwa',
        title: 'Aligning donor and government sexual and reproductive health financing in Malawi',
        watchTime: '4 min watch',
        link: 'https://youtu.be/Suk17pBL-V8',
        youtubeId: 'Suk17pBL-V8',
    }
];

export const VideoTelling = () => {
    const openModal = (index: number) => {
        return () => {
            setModalIsOpen(index);
        };
    }
    const closeModal = () => {
        setModalIsOpen(-1);
    };
    const modalIsOpen = (index: number) => {
        return modalIsOpenIndex === index;
    }
    const [modalIsOpenIndex, setModalIsOpen] = useState(-1);

    useGA("VideoTelling");
    return <ViewContainer mode={Mode.Dark}>
        <StyledVideoTelling className={classes.root}>
            <VideoTellingCaption />

            <div style={{backgroundColor:"#011E3F", paddingBottom:"40px", paddingTop:"50px"}}>
                <Container fixed>
                    <Grid container justifyContent={"center"} alignItems={"center"} columnSpacing={3}>
                        <Grid item md={6} sx={{ marginBottom:"10px"}}>
                            <img src={picstack} alt="" style={{ maxWidth:"100%", marginTop:"20px" }} />
                        </Grid>
                        <Grid item md={5.5} sx={{ marginBottom:"10px"}}>
                            <Typography variant={"h1"} component="div" className="videoHeadClass" sx={{color: "white", margin: "20px 0 20px 0", zIndex: 100, textAlign:"left", fontSize:"50px", lineHeight:"60px"}}>
                                Why is video telling included?
                            </Typography>
                            <Typography  component="div" sx={{color: "white", margin: "40px 0 40px 0", zIndex: 100, textAlign:"left"}}>
                                We utilize multiple forms of storytelling and dissemination channels to share our rich and growing repository of implementation stories that highlight our shared experiences. Our aim is to reach national-level stakeholders to both learn from and share experiences of the key interventions needed to shift towards universal access of sexual and reproductive health (SRH).
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>


            <Container fixed>
            <Grid container  sx={{ color: primary, marginTop: "50px", marginBottom: "70px" }} >
                <Grid item md={9}>
                <div className="quoteWrap quoteWrap--r" style={{ height: "100%" }}>
                    <div className="dropFlex dropFlex--r">
                    <Avatar src={serik} sx={{ height: "180px", width: "180px", maxWidth: "185px", flexGrow:"0", flexShrink:"0" }} className="dropAvatar" />
                    <div>
                        <div className="dropFlex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="78" height="68" viewBox="0 0 78 68" fill="none">
                                <path d="M77.2148 0.214844V12.2812C70.9291 13.3396 66.5291 15.5624 64.0148 18.9494C61.5006 22.3365 60.2434 28.3697 60.2434 37.049H76.272V67.2148H45.472V41.812C45.472 28.6872 47.8815 18.5261 52.7006 11.3286C57.5196 3.91942 65.691 0.214844 77.2148 0.214844ZM31.9577 0.214844V12.2812C25.672 13.3396 21.272 15.5624 18.7577 18.9494C16.2434 22.3365 14.9863 28.3697 14.9863 37.049H31.0148V67.2148H0.214844V41.812C0.214844 28.6872 2.62437 18.5261 7.44341 11.3286C12.2625 3.91942 20.4339 0.214844 31.9577 0.214844Z" fill="#E38A15"/>
                            </svg>
                            <div>
                                <Typography
                                className="dropQuote"
                                sx={{ marginBottom: "0", lineHeight: "30px", fontWeight: "500", fontSize: "20px" }}
                                >
                                    Healthy teenagers today are health teenagers in the future. As a result of implementing legal reforms,
                                                young people from the age of 16 years-old are now able to access youth-friendly health services without
                                                parental permission in Kazakhstan. These youth friendly health services are free of charge and covered
                                                by the national health insurance. Legal reforms for SRH and UHC can and must be replicated globally
                                </Typography>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </Grid>
                <Grid item md={3}>
                    <div className="captionBy__wrap" style={{padding: "25px 0"}}>
                        <Divider sx={{ width: "100%", background: "#E38A15", height: "5px", margin: "10px 0 15px "}} />
                        <span className="captionBy">
                            <h4>Dr Serik Tanirbergenov</h4>
                            <p>LSP Author & Programme Analyst on Sexual and Reproductive Health Rights, UNFPA&nbsp;Kazakhstan</p>
                        </span>
                    </div>
                </Grid>
            </Grid>
            </Container>

            <Container fixed>
                <Grid container sx={{margin: "50px 0 150px 0", justifyContent: "space-between" }}>
                    { videos.map((video, index)=>(
                        <Grid item sm={5.5} lg={3.5} key={video.link}>
                            <YouTubeModal isOpen={modalIsOpen(index)} videoId={video.youtubeId} onClose={closeModal} />
                            <Card className={videoTellingClasses.card} onClick={openModal(index)}>
                                <CardActionArea sx={{position: "relative"}}>
                                    <CardMedia
                                        component="img"
                                        className={videoTellingClasses.cardImg}
                                        image={video.image}
                                        alt={video.imagealt}
                                    />

                                    <CardContent className={videoTellingClasses.cardContent}>
                                        <Grid container justifyContent={"space-between"}>
                                            <Typography gutterBottom sx={{
                                                fontSize: "24px",
                                                display: "flex",
                                                alignItems: "center",
                                                color: primary
                                            }}>
                                                <PlayCircleFilledWhiteIcon htmlColor={"#E38A15"} />
                                                {video.country}
                                            </Typography>
                                            <Typography gutterBottom sx={{
                                                fontSize: "16px",
                                                color: "#2C4560",
                                                display: "flex",
                                                alignItems: "center",
                                                opacity: "0.65"
                                            }}>
                                                <HourglassBottomIcon fontSize="inherit" htmlColor={"#768697"} />
                                                {video.watchTime}
                                            </Typography>
                                        </Grid>
                                        <Typography gutterBottom sx={{ fontSize: "16px", lineHeight: "18px", color: primary, fontWeight: "bold" }}>
                                            {video.person}
                                        </Typography>
                                        <Typography gutterBottom sx={{ fontSize: "16px", lineHeight: "18px", color: primary, opacity: "0.65" }}>
                                            {video.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Container>

        </StyledVideoTelling>;
    </ViewContainer>
};
