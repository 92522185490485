import React from "react";
import { featureStoryClasses, StyledFeatureStory } from "./StyledFeatureStory";
import { Container, Grid, Typography, Button } from "@mui/material";
import { primary } from "../../common/theme";
import featureImage from "./trifold.png";

const pdfFileUrl = '/pdfs/LSP_MAGAZINE_VOLUME_I.pdf'; // Replace with the actual path to your PDF file

const openPDF = () => {
  window.open(pdfFileUrl, '_blank');
};

const YoutubeEmbed = ({ embedId, key }: any) => (
    <iframe
        width="100%"
        height="500"
        key={key}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
    />
);

export const FeatureStory = ({ key }: any) => {

    return <StyledFeatureStory className={featureStoryClasses.root}>
        <Container maxWidth={"lg"}>
            <Grid container justifyContent={"center"} sx={{position: "relative"}}>
                <Typography variant={"h1"} component="div" sx={{textAlign: "center", color: primary, margin: "30px 0", zIndex: 100}}>
                    What is the SRHR-UHC Learning by Sharing Portal
                </Typography>
            </Grid>
            <Grid container sx={{ margin: "20px 0 100px 0"}} justifyContent={"center"}>
                <Grid item xs={12} md={9} sx={{ marginBottom:"10px"}}>
                    <div className="videoWraps">
                        <YoutubeEmbed embedId={"EsxkKV1jKP4"} key={key} />
                    </div>
                </Grid>
            </Grid>
        </Container>
        <div className="gradient--up" style={{margin:"75px 0"}}>
            <Container maxWidth={"lg"}>
                <Grid container justifyContent={"center"} sx={{position: "relative", padding: "60px 0", alignItems:"center"}}>
                    <Grid item sm={5}>
                        <img src={featureImage} alt="Feature Image" style={{maxWidth:"100%"}} />
                    </Grid>
                    <Grid item sm={0.5}>
                    </Grid>
                    <Grid item sm={5}>
                        <Typography variant={"h3"} component="div" sx={{color: primary, fontWeight: "bold", fontSize:"32px", zIndex: 100, margin:"20px 0 15px 0"}}>
                            Read all the stories
                        </Typography>
                        <Typography component="div" sx={{color: primary, zIndex: 100, marginBottom:"20px"}}>
                        The latest stories on SRHR-UHC integration are available in Volume I (2023) of the LSP Magazine
                        </Typography>
                        <Button variant={"contained"} sx={{padding:"10px 35px", fontSize:"16px", borderRadius:"8px", textTransform:"none!important"}} onClick={openPDF}>
                            Download
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </StyledFeatureStory>;
};
